import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ImagesData from "../components/images"

const IndexPage = () => (
  <Layout>
    <SEO title="Solar Products" />
    <div className="container site-about row">
      <div className="col-sm-12">
        <div className="row">
          <marquee direction="left" height="50px">
  || Manufacturing in Nashik since 2017 ||
          </marquee>
        </div>
        <div className="row">
          <div className="col-md-5">
Our organization mainly focuses on manufacturing solar products which can be used easily in our day to day life.
Our one step to support and contribute to the concept of <span className="font-weight-bold">Make In India </span>
 and to promote the importance of renewable energy resources.
  <br/><br/>
We are introdusing eco friendly products Solar Niranjan / Samai / Laman Diwa.
It can be easily used to lighten up our Home Mandir on daily basis by replacing our Agneya Special diya used on oil & ghee.
  <br/><br/>
Experience our products & give your contribution in increasing the use of Natural Resources.
          </div>
          <div className="col-md-7">
            <div id="carouselBgFade" className="carousel slide carousel-fade" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active flashers">
                  <ImagesData imgName="agneya_products_shravan_rakshabandhan" className="img-thumbnail d-block w-100" width="40vw" alt="Agneya Special Solar Products" title="Agneya Products"/>
                </div>
                <div className="carousel-item flashers">
                  <div className="row">
                    <ImagesData imgName="agneya_products_shravan" className="img-thumbnail d-block w-100" width="40vw" alt="Agneya Special Solar Products" title="Agneya Products"/>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <ImagesData imgName="agneya_products_night_2" className="img-thumbnail d-block w-100" width="40vw" alt="Agneya Special Solar Products" title="Agneya Products"/>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <ImagesData imgName="agneya_products_night" className="img-thumbnail d-block w-100" width="40vw" alt="Agneya Special Solar Products" title="Agneya Products"/>
                  </div>
                </div>
                <div className="carousel-item flashers">
                  <div className="row">
                    <ImagesData imgName="agneya_products_shravan_deep_pujan" className="img-thumbnail d-block w-100" width="40vw" alt="Agneya Special Solar Products" title="Agneya Products"/>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <ImagesData imgName="agneya_collage" className="img-thumbnail d-block w-100" width="40vw" alt="Agneya Special Solar Products" title="Agneya Products"/>
                  </div>
              	</div>
              </div>
              <a className="carousel-control-prev" href="#carouselBgFade" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselBgFade" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
  <br/><br/>
  <h3 className="agney-rainbow">Vision : </h3>
As our country is blessed with nature ,
we as a proud Indian should contribute some thing to save the nature
and spread environmental awareness and take maximum advantage of Natural Resources.
Lets come together and try increasing the usage of solar technologies in our day to day life.
  <br/><br/>
  <h3 className="agney-rainbow">Mission : </h3>
By using solar products we as an individual will definitely save the consumption of non renewable resources
which itself is a contribution in saving environment.
Let the upcoming generation get introduced to different kind of solar products
and understand the importance of solar energy not just by reading them in books
but even by using them personally.
It is definitely possible to replace some of our daily routine items with such products which runs on solar energy .
      </div>
    </div>
<br/>
  </Layout>
)

export default IndexPage
